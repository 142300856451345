select {
    height:35px;
    &:focus-visible {
        border: 1px solid #E0E1E5;
    }
}

.inputContent {
    .select-loading{
        position: relative;
        .select-loading-txt{
            position: absolute;
            color: black;
            font-size: 12px;
            font-weight: 600;
            z-index: 99999;
            margin-left: 10px;
            margin-top: 10px;
            font-family: "Segoe UI" !important;
            color: rgba(0, 0, 0, 0.6);;
        }
    }
}
