*{
  box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    font-family: "Segoe UI"!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    color: $gris-p;
    width: 100%;
    min-height: 100vh;  
}
*:not(i){
  font-family: 'Segoe UI';
}
input[type="text"], input[type="password"]{
  height: 40px;
  color: #fff;
  border: 1px solid #E3E9F3;
  border-radius: 5px;
}
button {
  &:disabled {
    background-color: #f1f1f1!important;
    color: #999999!important;
  }
}
#root{
  position: relative;
  width: 100%;
  min-height: 100%;
}
.fontNormalize{
  font-family: "Segoe UI";
  font-size: 14px;
}
.MuiChip-label, button.MuiButton-root{
  font-family: "Segoe UI";
}
  
  code {
    font-family:"Segoe UI";
  }
  .dFlex{
    display: flex;
    display: -webkit-flex;
  }
  .dNone{
    display:none;
  }
  .f-direction-column{
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .titleContainer{
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: $rouge-p;
    margin: 0;
    padding: 0 0 20px 0;
    @extend .dFlex;
    align-items: center;
    .btnBack{
      width: 30px;
      height: 30px;
      font-size: 20px;
      @extend .dFlex;
      align-items: center;
      justify-content: center;
      background: #fff;
      color: #EFF0F2;
      border-radius: 100%;
      margin: 0 10px 0 0;
      transition: all 500ms;
      &:hover{
        background: $gris-p;
      }

  }
  }


  /*------for table-----*/
  .groupBtn{
    button{
      border-radius: 0;
      border-right: 1px solid;
      margin: 0!important;
      &:first-child{
          border-radius: 3px 0 0 3px
      } 
      &:last-child{
          border-radius: 0 3px 3px 0;
          border-right: none;
      }
    }

  }
  /* scrollBar*/
::-webkit-scrollbar {
  width: 10px;
  &:horizontal{
    height: 10px;
  }
}
::-webkit-scrollbar-track {
  background: rgba($color: #fff, $alpha: .0); 
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background:rgba($color: #AEAEAE, $alpha: .5); 
  transition: all 500ms;
  &:hover{
    background:rgba($color: #AEAEAE, $alpha: .6); 
  }
}
.loadingKardham{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: red;
  top: 0;
  left: 0;
  background-color: rgba($color: #fff, $alpha: .8);
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    width: 70px;
    height: auto;
    background: transparent;
    /*[class *= "anim-"]{
      stroke-dasharray: 20;
    }*/
    .anim-1{
      stroke-dasharray: 100;
      animation: anim1 infinite linear 10s;
    }
    .anim-2{
      stroke-dasharray: 100;
      animation: anim1 infinite linear 15s;
    }
  }
}
@keyframes anim1{
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 1000;
  }
}
@keyframes anim2{
  0% {
    stroke-dashoffset: 300;
  }
  15% {
    stroke-dashoffset: 300;
  }
  30%{
    stroke-dashoffset: 0;
  }
  45%{
    stroke-dashoffset: 0;
  }
  85%{
    stroke-dashoffset: 300;
  }
  100%{
    stroke-dashoffset: 300;
  }


}


/*spiner*/
.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #FC4237;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
       spinner-oaa3wk 1.6s infinite linear;
}

@keyframes spinner-bulqg1 {
  0% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
     clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
     clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
     clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-oaa3wk {
  0% {
     transform: scaleY(1) rotate(0deg);
  }

  49.99% {
     transform: scaleY(1) rotate(135deg);
  }

  50% {
     transform: scaleY(-1) rotate(0deg);
  }

  100% {
     transform: scaleY(-1) rotate(-135deg);
  }
}

/* progress*/
.progressbar {
  width: 100px;
  height: 15px;
  border-radius: 22.4px;
  color: #56565A;
  border: 2.2px solid;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    margin: 2.2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: #FC4237;
    animation: progress-pf82op 3s infinite;
  }
}


@keyframes progress-pf82op {
  100% {
     inset: 0;
  }
}
.progressbarPerso{
  width: 150px;
  height: 10px;
  background-color: $gris-t;
  border-radius: 10px;
  position: relative;
  transition: all 500ms;
  &::after{
    width: 8px;
    height: 8px;
    background:$rouge-p;
    top: 1px;
    left: 1px;
    right: auto;
    content: "";
    position: absolute;
    border-radius: 8px;
    animation: progressBarPersoanimation 5s infinite;

  }
}
@keyframes progressBarPersoanimation {
  0%{
    width: 8px;
    left: 1px;
    right: auto;
  }
  24%{
    width: calc(100% - 2px);
    left: 1px;
    right: auto;
  }
  24.5%{
    width: calc(100% - 2px);
    left: auto;
    right: 1px;
  }
  50%{
    width: 8px;
    left: auto;
    right: 1px;
  }
  74%{
    width: calc(100% - 2px);
    left: auto;
    right: 1px;
  }
  74.5%{
    width: calc(100% - 2px);
    left: 1px;
    right: auto;
  }
  100%{
    width: 8px;
    left: 1px;
    right: auto;
  }

}
div[class*="MuiAlert-standard"]{
  position: fixed;
  bottom: 40px;
  left: 5px;
  min-width: 250px;
  max-width: 300px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 10px rgba($color: #000, $alpha: .6);
  padding: 0;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  animation: framealert 1s 1;
  .MuiAlert-icon{
   height: 60px;
   width: 60px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0;
   svg{
    fill: #fff;
    width: 30px;
    height: auto;
   }
  }
  .MuiAlert-message{
    padding: 10px 16px;
    font-size: 12px;
    font-weight: normal;
    display: flex;
    align-items: center;
  }
  &.MuiAlert-standardWarning{
    .MuiAlert-message{
      color: #e29422;
    }
    .MuiAlert-icon{
      background-color: #e29422;
      svg{
        
      }
    }
  }
  &.MuiAlert-standardError{
    .MuiAlert-message{
      color: #cd2c04;
    }
    .MuiAlert-icon{
      background-color: #cd2c04;
      svg{
        
      }
    }
  }  
  &.MuiAlert-standardSuccess{
    .MuiAlert-message{
      color: #578e26;
    }
    .MuiAlert-icon{
      background-color: #578e26;
      svg{
        
      }
    }
  }
}
@keyframes framealert{
  0%{
    left: -350px;
    opacity: 0;
  }
  100%{
    left: 5px;
    opacity: 1;
  }

}

section.corps{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  background-color: #EFF0F2;
}

div.containerPrincipal{
  margin: 0 auto;
  width: 1360px;
  height: auto;
  padding: 0 16px;
  box-sizing: border-box;
  @media screen and (max-width : 1366px){
    width: 100%;
  }
  & > div {
    // @media (max-width: 768px) {
    //   flex-direction: column;
    // }
  }
}
.MuiButton-root.MuiButton-text{
  text-transform: none;
}

/*Button*/
.btn {
  width: 210px;
  color: #fff !important;
  height: 40px;
  &.primary {
    background: #F9423A;
    &.inverse {
      font-weight: 600;
      background: transparent;
      color: #F9423A !important;
    }
  }
  &.secondary {
    background: #C5C5C5;
    &:hover {
      background-color: #C5C5C5;
    }
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
@media screen and (min-width : 768px){
    .menuForDesktopMobile{
      display: flex;
    }
    .menuForMobile{
      display: none;
    }
    .cttLogo {
      .desktop{
        display: block;
      }
      .mobile{
        display: none;
      }

    }
}
@media screen and (max-width : 1024px){
  .n-s-tablet{
    display: none!important;
  }
}

@media screen and (max-width : 768px){
  .n-s-mobile{
    display: none!important;
  }
  .cttLogo {
    .desktop{
      display: none;
    }
    .mobile{
      display: block;
    }
  }
  .menuForDesktopMobile{
    display: none;
  }
  header{
    height: 90px;
  }
  .menuForMobile{
    display: block;
    .forMobile{
      display: block;
      position: absolute;
      top: 23px;
      left: 55px;
      width: 40px;
      height: 40px;
      p {
          position: relative;
          width: 40px;
          height: 40px;
          margin: 0;
          padding: 0;
          transition: all 500ms;
          span {
              width: 100%;
              height: 2px;
              background-color: #fff;
              position: absolute;
              top: calc(50% - 1px);
              left: 0;
              transition: all 500ms
          }
      }
      svg {
        fill: #fff;
        font-size: 45px;
        background: #F9423A;
      }
    }
  }
  .containerMenuForMobile {
    display: flex;
    flex-direction: column;
    transition: all 500ms;
    height: calc(100vh - 47px);
    background-color: #F8F8F8;
    width: 100%;
    position: absolute;
    top: ptr(47);
    left: -100vw;
    overflow-y: auto;
    ul {
      list-style: none;
      padding-left: 15px;
      li {
        padding: 10px 0;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
  .openMenu {
    .containerMenuForMobile {
      left: 0;
      top: auto;
      z-index: 99;
    }
  }
  .menuBasHeader {
    height: auto !important;
  }
  .allLinkMenu {
    .txtTicket,
    .MuiTouchRipple-root  {
      display: none;
    }
    .addTicket {
      width: auto;
    }
    .menuUser {
      font-size: 0;
      padding: 0;
      justify-content: end;
      .achron {
        margin: 0;
      }
    }
  }
  text {
    display: none;
  }
}
.MuiTablePagination-root {
  color: #fff !important;
  .MuiTablePagination-actions {
    width: 90px;
    display: flex;
    justify-content: space-between;
    button {
      background-color: #47494e !important;
      color: rgba(255, 255, 255, 0.26);
    }
  }
  svg {
    fill: #ffffff;
  }
}
.MuiTablePagination-root{
  div [class*="MuiInputBase-colorPrimary"] {
      background: #32353d;
      border-radius: 3px;
      border: 1px solid #fff;
      .MuiSelect-select{
          min-width: 30px;
          text-align-last: left;
      }        
  } 
  .MuiTablePagination-actions{
      width: 90px;
      display: flex;
      justify-content: space-between;
      button{
          background: #47494e!important;
          &[disabled]{
              background: #00ffff05!important;
              svg path{
                  fill: #9B9B9B !important;
              } 
          }
      }
  }
}
.MuiDialogContent-root,
.MuiDialogTitle-root,
.MuiDialogActions-root {
  background: #F9F9F9 !important;
}