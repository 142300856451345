@font-face {
    font-family: 'Segoe UI';
    src: url('../font/SegoeUI-BoldItalic.woff2') format('woff2'),
        url('../font/SegoeUI-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../font/SegoeUI.woff2') format('woff2'),
        url('../font/SegoeUI.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../font/SegoeUI-Italic.woff2') format('woff2'),
        url('../font/SegoeUI-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../font/SegoeUI-Bold.woff2') format('woff2'),
        url('../font/SegoeUI-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../font/SegoeUI-Semibold.woff2') format('woff2'),
        url('../font/SegoeUI-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

