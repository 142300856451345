::-webkit-file-upload-button {
    cursor:pointer;
}

.u-image {
    width: 100%;
    height: 80%;
    overflow:hidden;
    text-align: center;

    input[type=file]{
        position: absolute;
        opacity: 0;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        cursor: pointer;
    }

    button {
        align-items: center;
        margin-top: 10px;
        span{
            font-size: x-small;
            &.uploadLogoImgInfo.error{
                color: rgb(78, 68, 68);
            }
        }
        svg {
            margin-right: 10px;
        }
    }

    img {
        width: 95%;
        height: 100%;
        object-fit: contain;
        border-radius: 2px;
        display: block;
        margin: 0 auto;
    }

    .edit {
        position: absolute;
        z-index: 98;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.5s;   
        .MuiAvatar-root {
            width:100%;
            height: 100%;
            border-radius: 0px;
            background-color: rgba(0,0,0,0.5);
            align-items: center;
            flex-direction: column;
            svg {
                width: 35px;
            }
            span{
                font-size: small;
                margin-top: 5px;
                font-weight: 800;
                &.uploadLogoImgInfo.error{
                    color: red;
                }
            }
        }
    }

    &:hover {
        cursor: pointer;
        .edit {
            opacity: 1;
            &:hover{
                cursor: pointer;
            }
        }
    }
}