@font-face {
  font-family: 'icomoon';
  src: url('../font/icomoon.eot?jkb1tn');
  src: url('../font/icomoon.eot?jkb1tn#iefix') format('embedded-opentype'),
    url('../font/icomoon.ttf?jkb1tn') format('truetype'),
    url('../font/icomoon.woff?jkb1tn') format('woff'),
    url('../font/icomoon.svg?jkb1tn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-contrat:before {
  content: "\e900";
}

.icon-add-materiel:before {
  content: "\e901";
}

.icon-calendar:before {
  content: "\e902";
}

.icon-contrat:before {
  content: "\e903";
}

.icon-contrat-round:before {
  content: "\e904";
}

.icon-delete:before {
  content: "\e905";
}

.icon-edit:before {
  content: "\e906";
}

.icon-info:before {
  content: "\e907";
}

.icon-location:before {
  content: "\e908";
}

.icon-materiel:before {
  content: "\e909";
}

.icon-search:before {
  content: "\e90a";
}

.icon-select:before {
  content: "\e90b";
}

.icon-societe:before {
  content: "\e90c";
}

.icon-time:before {
  content: "\e90d";
}

.icon-document:before {
  content: "\e910";
}

.icon-no_sim:before {
  content: "\e90e";
}

.icon-service:before {
  content: "\e90f";
}

.icon-back:before {
  content: "\e911";
}

.icon-attente:before {
  content: "\e912";
}

.icon-comment:before {
  content: "\e913";
}

.icon-confirm:before {
  content: "\e914";
}

.icon-escale:before {
  content: "\e915";
}

.icon-new-ticket:before {
  content: "\e916";
}

.icon-user-assign:before {
  content: "\e917";
}

.icon-solved:before {
  content: "\e918";
}

.icon-closed:before {
  content: "\e919";
}

.icon-audio:before {
  content: "\e91a";
}

.icon-smart-building:before {
  content: "\e91b";
}

.icon-smart-workplace:before {
  content: "\e91c";
}

.icon-cyber:before {
  content: "\e91d";
}

.icon-procedure:before {
  content: "\e91e";
}

.icon-guides:before {
  content: "\e91f";
}