input[type=text], input[type=email], input[type=password]{
    height: 40px;
    border: 1px solid #E3E9F3;
    border-radius: 5px;
    background-color: #fff;
    color: $gris-p;
    padding: 0 16px;
    color: $gris-p;
    font-size: 14px;
    font-family: "Segoe UI";
    &:focus, &:focus-within, &:focus-visible{
        outline: none;
        box-shadow: 0 0 5px rgba($color: #000, $alpha:.16);
    }
    &:-internal-autofill-selected{
        background-color:#fff!important; 
    }
}
button{
    border-radius: 5px;
    border: none;
    cursor: pointer;
    &.save{
        background-color: $rouge-p;
        color: #fff;
        &:hover, &.Mui-disabled{
            background: rgba($color: $rouge-p, $alpha:.8)!important;
            color: #fff!important
        }
        .MuiLoadingButton-loadingIndicator{
            left: 13px;
        }
        
    }
   
}
.breadcrumbs{
    margin: 0 0 30px 0;
    @extend .dFlex;
    max-width: 100%;
    min-width: 200px;
    span{
        height: 30px;
        padding: 0 16px;
        @extend .dFlex;
        align-items: center;
        text-decoration: none;
        color: $vert-p;
        position: relative;
        font-weight: regular;
        &:before{
            position: absolute;
            width: 5px;
            height: 5px;
            border-color: #bfc0c2!important;
            border-right:2px solid;
            border-bottom: 2px solid;
            transform: rotate(-45deg);
            content: "";
            top: 15px;
            left: -4px
        }
        &:first-child{
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            padding-left: 0;
            &:before{
                display: none;
            }
        }
        &:last-child{
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }

}
.MuiTablePagination-toolbar *{
    font-family: "Segoe UI"!important;
}
.btnUp {
    font-family: "Segoe UI"!important;

}