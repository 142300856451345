.MuiModal-root{
    .MuiBackdrop-root{
        background: rgba($color : #000, $alpha: 0.55);
        
    }
    .MuiDialog-container{
        font-family: "Segoe UI";
        color: $gris-p;
        h2{
            font-family: "Segoe UI";
        }
        .MuiPaper-elevation{
            max-width: 800px;
            .MuiDialogTitle-root{
                color: $gris-p;
                height: 60px;
                box-sizing: border-box;
                padding: 0 20px;
                @extend .dFlex;
                flex-direction: column;
                justify-content: center;
                background: #EFF0F2;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.125;
                span{
                    display: block;
                    text-transform: none;
                    color: #AEABAB;
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }
        .MuiDialogContent-root{
            border: none;
        }

        
    }
    .footerContent{
        padding: 20px 24px 40px 24px;
        button{
            height: 40px;
            min-width: 120px;
            text-transform: none;
            background:$gris-p;
            color: #fff;
            &.save{
                background: $rouge-p;

            }
            &.Mui-disabled{
                background: rgba($color: $gris-p, $alpha: .05);
                color: $gris-p;
            }
            :hover{  
                box-shadow: 0 0 6px rgba($color: #000000, $alpha: .2)
            }
        }
    }
    &.DeletePopup {
        .MuiDialog-container{
            .MuiPaper-root{
                width: 400px;
                .MuiDialogTitle-root{
                    background: #fff;
                    text-align: center;
                    justify-content: center;
                    padding: 40px 0 20px 0;
                    box-sizing: border-box;
                    height: auto;
                }
                .deleteContent{
                    @extend .dFlex;
                    flex-direction: column;
                    align-items: center;
                    font-weight: 600;
                    text-align: center;
                    font-size: 13px;
                    color:#8a8989;
                    span.icon-delete, span.icon-danger{
                        border-radius: 100%;
                        @extend .dFlex;
                        justify-content: center;
                        align-items: center;
                        width: 60px;
                        height: 60px;
                        background: #f15e5e;
                        color: #fff;
                        font-size: 30px;
                        margin-bottom: 20px
                    }
                }
                .MuiDialogActions-root{
                    padding: 40px 16px;
                    justify-content: center;
                    button{
                        width: 150px;
                        box-shadow: none;
                        border-radius: 3px;
                        height: 40px;
                        background: $rouge-p;
                        color: #fff;
                        &.cancelBtn{
                            background: $gris-s;
                        }
                    }
                }
            }
        }
    }
    &.successPopup{
        .MuiDialog-container{
            .MuiPaper-elevation{
                width: 400px;
            }
            h2.successTitle{
                @extend .dFlex;
                font-weight: 300;
                flex-direction: column;
                align-items: center;
                background: #3ab274;
                padding: 20px 0 40px 0;
                height: auto;
                color: #fff;
                margin: 0 0 30px 0;
                svg{
                    width: 50px;
                    height: auto;
                    margin: 0 0 16px 0;
                }
    
            }

        }
        .successClose{
            background: transparent;
            margin: 10px auto 30px auto;
            box-shadow: none;
            color: #3ab274;
            font-weight: bold;
            
        }
    }
}
.stepMateriel{
    min-width: 200px;
    max-width: 600px;
    width: 200px;
    margin: 30px auto;
    align-items: flex-start!important;
    .MuiStepLabel-root{
        @extend .dFlex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        white-space: nowrap;
    }
    .MuiStepLabel-root {
        width: 40px;
        overflow: visible;
        *, .Mui-active{
            color: $vert-p;
            border-color: $vert-p;
            font-family: "Segoe UI";
            font-size: 12px;
        }
        .MuiStepLabel-labelContainer{
            @extend .dFlex;
            justify-content: center;
            font-family: "Segoe UI";
            font-size: 12px;
        }
        .MuiStepLabel-iconContainer{
            margin: 0 0 10px 0;
            padding: 0;
            box-sizing: border-box;
            height: 30px;
            width: 30px;
            @extend .dFlex;
            align-items: center;
            justify-content:center;
            border: 2px solid $vert-p;
            border-radius: 100%;

        }
        &.Mui-disabled{
            *{
                color: #E6E2E2;
                border-color: #E6E2E2;
                font-family: "Segoe UI";
                font-size: 12px
            }
            
        }
    }
    .MuiStepConnector-root{
        margin: 16px 0 0 0;
        span{
            border-top:2px solid #E6E2E2;
        }
        &.Mui-active{
            span{
                border-color:$vert-p;
            } 
        }
    }
    &.stepContrat{
        width: 600px;
    }
}
.MuiDataGrid-panelContent{
    .MuiDataGrid-filterForm{
        padding: 16px;
        .MuiFormControl-root{
            padding: 16px 16px 16px 0; 
            .MuiFormControl-root{
                padding: 0;
            }
            label{
                position: static;
            }
            .MuiInput-root {
                border: none;
                &::before, &:after{
                    display: none;
                }
                input, select{
                    width: 100%;
                    border: 1px solid #E0E1E5;
                    height: 40px;
                    padding: 0 10px;
                    &:focus{
                        box-shadow: none;
                    }
                }
            }
        }
    }
}